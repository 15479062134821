<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card class="transparent" align="center" flat>
            <v-card class="pa-5 mr-3 mb-3 mt-11" width="600" align="center" flat>
              <v-img
                alt="Vuetify Logo"
                src="@/assets/img/payment/error.svg"
                width="150"
              />
    
              <!-- Email Verification Needed -->
              <h1 class="mt-10">Email Verification Needed For Full Access</h1>
    
              <p>Check your inbox for our verification link to unlock all features. Can't find it? Check your spam folder, click 'Resend' or contact support.</p>
    
              <!-- <h5>We Love You!</h5>     -->
        
              <!-- Resend Email -->
              <v-btn
                @click="requestVerifyEmail()"
                color="btn btn-primary white--text mt-4"
              >
                Resend Email
              </v-btn>
            </v-card>
          </v-card>

          <v-layout class="mt-n4" justify-center>
            <!-- Logout -->
            <v-btn
              @click="logOut()"
              color="btn btn-primary white--text mt-4"
            >
              Log Out
              </v-btn>
          </v-layout>

        <!-- Overlay -->
        <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-card>
  </div>
</template>
  
<script>
import { mapActions, mapMutations , mapState} from "vuex";

export default {
  name: 'DocumentManagementRequestVerifyEmail',

   data: () => ({
    overlay: false,
   }),

  computed: {    
    ...mapState({ auth: "auth" }),
    
    // showVerifyEmail
    showVerifyEmail() {
      return this.$store.getters['auth/getShowVerifyEmail'];
    }
  },

  async created() {
    if (this.auth.loggedIn) {
      // showVerifyEmail
    if(!this.showVerifyEmail){      
      this.$router.push({ name: "DashboardInbox" });
    } else {
      //  getUserDetails
      await this.getUserDetails();
    }

    } else {
      this.$router.push({ name: "Login" });
    }
  },

  watch: {
    // showVerifyEmail
    showVerifyEmail(val){
      // showVerifyEmail
      if(!val){      
        this.$router.push({ name: "DashboardInbox" });
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      logOutUser: "auth/logOutUser",
    }),

    // mapMutations
    ...mapMutations({
      resetAuthState: "auth/resetState",
      
      setUser: "auth/setUser",
    }),

    logOut() {
      this.resetAuthState();

      this.$router.push({ name: "Login" });
    },

    // requestVerifyEmail
    async requestVerifyEmail() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        `request-verify-email`,
        {
          
        },
        "Please check your email for verification"
      );

      if (res.status == 200) {
        //
      } else if(res.status == 422) {
        // data
        let data = res.data;

        if(data && "message" in data && data.message === "Email Already Verified."){
          // getUserDetails
          await this.getUserDetails();
        }
      }

      this.overlay = false;
    },
 
    // getUserDetails
    async getUserDetails() {
      // this.overlay = true;

      const res = await this.$helpers.getApiData("user-details");

      if (res.status == 200) {
        const data = res.data;

        this.setUser(data);
      }

      // this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
