<template>
    <div class="background py-16">
        <RequestVerifyEmail />
    </div>
</template>

<script>
import RequestVerifyEmail from "@/components/elements/auth/request-verify-email/RequestVerifyEmail";

export default {
    name: 'DocumentManagementVerifyEmail',

    components: {
        RequestVerifyEmail,
    },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
//
</style>